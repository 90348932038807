import { JsonRpcProvider, WebSocketProvider } from "@ethersproject/providers";
import {
	connectorsForWallets,
	getDefaultWallets,
} from "@rainbow-me/rainbowkit";
import { ledgerWallet } from "@rainbow-me/rainbowkit/wallets";
import { Provider } from "@wagmi/core";
import { useMemo } from "react";
import {
	Chain,
	configureChains,
	createClient,
	useProvider,
	useWebSocketProvider,
} from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { fantomTestnet, mainnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { ALCHEMY_API_KEY } from "../constants";
import { rabbyWallet } from '@rainbow-me/rainbowkit/wallets';

export const appInfo = {
	appName: "Spaace",
};

export const useWagmi = (isMainnet: boolean) => ({
	...useMemo(() => {
		const { chains, provider, webSocketProvider } = configureChains<Chain>(
			isMainnet ? [mainnet] : [fantomTestnet],
			[
				...(ALCHEMY_API_KEY !== undefined
					? [alchemyProvider({ apiKey: ALCHEMY_API_KEY })]
					: []),
				publicProvider(),
			],
		);

		const wallets = [
			...getDefaultWallets({
				appName: appInfo.appName,
				chains,
			}).wallets,
			{
				groupName: "Other",
				wallets: [ledgerWallet({ chains }), rabbyWallet({ chains })],
			},
		];

		const wagmi = createClient<Provider, WebSocketProvider>({
			autoConnect: true,
			connectors: connectorsForWallets(wallets),
			provider,
			webSocketProvider,
		});

		return {
			chains,
			wallets,
			wagmi,
		};
	}, [isMainnet]),
	provider: useProvider<JsonRpcProvider>(),
	webSocketProvider: useWebSocketProvider<WebSocketProvider>(),
});
