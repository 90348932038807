import "./assets/styles/globals.scss";
import "./assets/styles/hide-modal.scss";
import "@rainbow-me/rainbowkit/styles.css";
import {RainbowKitProvider} from "@rainbow-me/rainbowkit";
import {WagmiConfig} from "wagmi";
import {appInfo, useWagmi} from "./services";
import {TESTNET} from "./constants";
import {Buffer} from "buffer";
import {PrivateSaleView} from "./views";

function App() {
    window.Buffer ??= Buffer;

    const {chains, wagmi} = useWagmi(!TESTNET);

    return (
            <WagmiConfig client={wagmi}>
                <RainbowKitProvider appInfo={appInfo} chains={chains} modalSize="compact">
                    <PrivateSaleView/>
                </RainbowKitProvider>
            </WagmiConfig>
    );
}

export default App;
