import React, { useState } from "react";

// Styles
import styles from "./styles.module.scss";

import { OptionCard } from "src/components";

import {formatNumber, fromWei, toWei} from "src/utils";
import {DEPOSIT_OPTIONS, PRIVATE_SALE_PRICE} from "src/constants";
import BuyRecap from "./BuyRecap";
import {useAccount, useNetwork, useSwitchNetwork} from "wagmi";
import { useDisconnect } from "wagmi";
import {useUsdtBalance} from "../../../../services";
import ConnectWalletButton from "../../../ConnectWalletButton";
import usdtLogo from "../../../../assets/images/usdt-logo.png";

interface MainPanelProps {
	amount: number;
	setAmount: (value: number) => void;
	write?: () => void;
	isLoading?: boolean;
	isHidden?: boolean;
}

export const SalePanel = ({
	amount,
	setAmount,
	write,
	isLoading,
	isHidden,
}: MainPanelProps) => {
	const [activeOptionCardIndex, setActiveOptionCardIndex] = useState<number | null>(0);

	const { address, isConnected} = useAccount();

	const { chain, chains } = useNetwork();
	const { switchNetwork } = useSwitchNetwork();
	const { disconnect } = useDisconnect();

	const { balance } = useUsdtBalance(address);

	const buy = async () => {
		if (chain === undefined || chain.unsupported) {
			if (switchNetwork !== undefined) {
				switchNetwork(chains[0].id);
			} else {
				disconnect();
			}
			return;
		}

		if (write !== undefined) {
			write();
			return;
		}
	};

	const toggleOptionCardActive = (index: number) => {
		setActiveOptionCardIndex(index);
		setAmount(DEPOSIT_OPTIONS[index]);
	};

	const minAmount = toWei(500);
	const maxAmount = toWei(100_000);

	const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value;

		// Remove leading zeros only if the value is not just "0"
		if (value !== "" && value !== "0") {
			value = value.replace(/^0+/, '');
		}

		let computedValue = value;
		if(toWei(value).gt(maxAmount)) computedValue = fromWei(maxAmount);

		setActiveOptionCardIndex(null); // Deactivate cards when custom input is used

		setAmount(Number(computedValue));
	};

	const isButtonDisabled =
		isLoading ||
		balance === undefined ||
		balance === null ||
		toWei(amount).gt(maxAmount) ||
		toWei(amount).lt(minAmount) ||
		toWei(amount).gt(balance) ||
		(!!balance && balance.lt(toWei(amount)));

	return (
		<div className={isHidden ? "opacity-0" : styles.container}>
			<div className={styles.head}>
				<div className={styles.price}>
					<span className={styles.title}>Private Sale Price</span>
					<span className={`${styles.subtitle} spaace-txt-color`}>
						${formatNumber(PRIVATE_SALE_PRICE, 18, 2)}
					</span>
				</div>
				{/*
				<div className={styles.arrow}>
					<ArrowRounded width="40" height="40" color="#909090" />
				</div>

				<div className={styles.price}>
					<span className={styles.title}>TGE Listing price</span>
					<span className={styles.subtitle}>$0.40</span>
				</div>
				*/}
			</div>

			<div className={styles.cards}>
				<OptionCard
					level={0}
					price={DEPOSIT_OPTIONS[0]}
					toggleOptionCardActive={() => toggleOptionCardActive(0)}
					isActive={activeOptionCardIndex === 0 || amount === DEPOSIT_OPTIONS[0]}
				/>
				<OptionCard
					level={1}
					price={DEPOSIT_OPTIONS[1]}
					toggleOptionCardActive={() => toggleOptionCardActive(1)}
					isActive={activeOptionCardIndex === 1 || amount === DEPOSIT_OPTIONS[1]}
				/>
				<OptionCard
					level={2}
					price={DEPOSIT_OPTIONS[2]}
					toggleOptionCardActive={() => toggleOptionCardActive(2)}
					isActive={activeOptionCardIndex === 2 || amount === DEPOSIT_OPTIONS[2]}
				/>
			</div>

			<div className={styles.customAmount}>
				<label htmlFor="customAmount">Or enter custom amount:</label>
				<div className="relative">
                    <input
                        id="customAmount"
                        type="number"
                        value={Number(amount).toString()}
                        onChange={handleCustomAmountChange}
                        placeholder="Enter custom amount"
                    />
                    <div className={styles.usdtContainer}>
                        USDT<img className={styles.usdPrice} src={usdtLogo} alt="Usdt logo"/>
                    </div>
				</div>
			</div>

			<BuyRecap
				tokenAmount={formatNumber(
					toWei(amount).div(PRIVATE_SALE_PRICE),
					0,
					2,
				)}
			/>

			<div className={`flex items-center justify-center`}>
				{
					isConnected ?
						(<button
							onClick={buy}
							type="button"
							className={`mt-[18px] w-full rounded-[40px] p-3 text-white flex justify-center ${
								isButtonDisabled ? "disabled bg-[#F5F4F4] " : "bg-[#FF7225] "
							} ${isLoading ? "pr-3" : ""}`}
							disabled={isButtonDisabled}
						>
							{isLoading ? (
								<div className="loader"/>
							) : chain === undefined || chain.unsupported ? (
								<p>Switch network</p>
							) : (
								<p>Buy $SPAACE</p>
							)}
						</button>) :
						<ConnectWalletButton/>
				}
			</div>

			{balance?.lt(toWei(amount)) && (
				<p className={styles.error}>
					You have insufficient funds to purchase this amount of $SPAACE. Please
					add more USDT to your wallet to continue.
				</p>
			)}
		</div>
	);
};
