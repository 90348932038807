import UsdtABI from "../utils/contracts/UsdtABI.json";

import { toWei } from "../utils";
import {
	useContractRead,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from "wagmi";
import {
	USDT_CONTRACT,
	MULTISIG_ADDRESS,
} from "../constants";
import {BigNumber} from "ethers";

export const useUsdtDeposit = (
	amount: number,
) => {
	const { config, error, isError} = usePrepareContractWrite({
		address: USDT_CONTRACT,
		abi: UsdtABI,
		functionName: "transfer",
		args: [MULTISIG_ADDRESS, toWei(amount)?.div(10 ** 12)]
	});

	if(isError) console.log(error)

	const write = useContractWrite(config);
	const { isSuccess, isLoading} = useWaitForTransaction({ hash: write.data?.hash });
	return { write, isSuccess, isLoading };
};

export const useUsdtBalance = (address: `0x${string}` | undefined) => {
	const { data, isLoading } = useContractRead({
		address: USDT_CONTRACT,
		abi: UsdtABI,
		functionName: "balanceOf",
		args: [address],
	});

	return { balance: (data as BigNumber)?.mul(10 ** 12), isLoading };
}
