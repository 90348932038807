// Styles
import styles from "./styles.module.scss";
import { Button } from "../../ui";

export const Footer = () => {
	return (
		<div className={styles["container"]}>
			<div>
				<p className={styles["copyright"]}>
					{" "}
					All rights reserved. &copy; 2024 Spaace{" "}
				</p>
				<a
					className={`${styles["copyright"]}`}
					target="_blank"
					rel="noreferrer"
					href="https://spaace-marketplace.notion.site/SPAACE-Token-Private-Sale-Terms-Conditions-47a80518e3d045d7b904997ccdd94693?pvs=4"
				>
					Sale Terms & Conditions
				</a>
			</div>


			<div className={styles["informations"]}>
				<a
					className={`${styles["link"]}`}
					target="_blank"
					rel="noreferrer"
					href="https://spaace-marketplace.notion.site/SPAACE-Token-Private-Sale-FAQs-ec591d8b426f47e0bff9788503b84fd2?pvs=4"
				>
					Have questions? Read our FAQ
				</a>

				<Button
					href="https://litepaper.spaace.io"
					target="_blank"
					className="cursor-pointer text-[#FF7225]"
					label="Litepaper"
					variant="btn-secondary"
				/>
			</div>
		</div>
	);
};
