import React from "react";
import { BaseIconProps } from "./types";

const WeirdIcon: React.FC<BaseIconProps> = ({
	height = 26,
	width = 26,
	color = "none",
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 26 26"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24.3201 9.22678L16.7735 1.68012C14.6935 -0.399883 11.3068 -0.399883 9.22679 1.68012L1.68012 9.22678C-0.399883 11.3068 -0.399883 14.6934 1.68012 16.7734L9.22679 24.3201C10.2668 25.3601 11.6268 25.8801 13.0001 25.8801C14.3735 25.8801 15.7335 25.3601 16.7735 24.3201L24.3201 16.7734C26.4001 14.6934 26.4001 11.3068 24.3201 9.22678ZM22.4401 11.1068C22.6135 11.2801 22.7335 11.4668 22.8535 11.6534H19.0801C18.9601 11.4534 18.8401 11.2668 18.6668 11.1068L14.8935 7.33345C14.7201 7.16012 14.5335 7.04012 14.3468 6.92012V3.14678C14.5468 3.26678 14.7335 3.38678 14.8935 3.56012L22.4401 11.1068ZM13.0135 16.7601L9.24012 12.9868L13.0135 9.21345L16.7868 12.9868L13.0135 16.7601ZM11.1335 3.56012C11.3068 3.38678 11.4935 3.26678 11.6801 3.14678V6.92012C11.4801 7.04012 11.2935 7.16012 11.1335 7.33345L7.36013 11.1068C7.1868 11.2801 7.0668 11.4668 6.9468 11.6534H3.17346C3.29346 11.4534 3.41346 11.2668 3.58679 11.1068L11.1335 3.56012ZM3.58679 14.8801C3.41346 14.7068 3.29346 14.5201 3.17346 14.3334H6.9468C7.0668 14.5334 7.1868 14.7201 7.36013 14.8801L11.1335 18.6534C11.3068 18.8268 11.4935 18.9468 11.6801 19.0668V22.8401C11.4801 22.7201 11.2935 22.6001 11.1335 22.4268L3.58679 14.8801ZM14.9068 22.4268C14.7334 22.6001 14.5468 22.7201 14.3601 22.8401V19.0668C14.5601 18.9468 14.7468 18.8268 14.9068 18.6534L18.6801 14.8801C18.8535 14.7068 18.9735 14.5201 19.0935 14.3334H22.8668C22.7468 14.5334 22.6268 14.7201 22.4535 14.8801L14.9068 22.4268Z"
				fill="#646463"
			/>
		</svg>
	);
};

export default WeirdIcon;
