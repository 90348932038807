import type { BaseIconProps } from "./types";

const SpaaceLogo = ({ height, width }: BaseIconProps) => (
	<svg
		width={width ?? "425"}
		height={height ?? "78"}
		viewBox="0 0 425 78"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M39.0895 38.5076C39.0895 50.5206 51.4868 56.3777 63.8888 56.6857C80.3152 56.6857 87.7583 48.3644 88.0682 40.0524C88.3782 27.1154 75.9809 23.7178 64.8186 22.1824C57.3896 20.9503 53.661 20.3342 53.9709 16.6333C53.9709 12.937 59.8644 12.0129 63.2736 12.0129C67.3027 12.0129 73.1915 13.245 73.1915 18.1781H85.8987C85.5888 7.39724 76.596 0.308024 63.5789 0C50.5617 0 41.5689 6.15581 40.9491 16.0172C39.7093 30.8024 57.3849 33.2759 62.959 34.1953C70.4021 35.1194 74.4265 36.6595 75.0464 39.133C75.6662 43.1373 71.3178 44.6774 64.1987 44.6774C54.896 44.6774 52.4213 40.3651 51.7873 38.5123H39.0801H39.0895V38.5076Z"
			fill="#232322"
		/>
		<path
			d="M372.211 22.724C371.295 19.0883 369.468 16.3628 366.726 14.538C364.289 12.7225 360.922 11.8125 356.963 11.8125C353.615 11.8125 350.553 12.7225 348.116 14.8414C345.373 16.9695 343.542 19.387 342.631 22.724H372.22H372.211ZM342.011 32.7301C342.622 36.6737 344.143 40.0106 346.9 42.1294C349.642 44.2483 352.995 45.457 357.268 45.457C360.011 45.457 362.767 44.8503 364.899 43.6416C367.336 42.4328 368.863 40.916 370.384 38.7925H384.11C381.673 44.5563 378.311 49.0973 373.742 52.4342C369.168 55.7618 363.678 57.2926 357.569 57.2926C353.605 57.2926 349.938 56.6859 346.28 55.1738C342.932 53.965 339.87 51.8462 337.127 48.8126C334.69 46.3904 332.553 43.0489 331.023 39.7166C329.806 36.0763 328.891 32.7394 328.891 28.8051C328.891 25.1695 329.806 21.5245 331.023 18.1969C332.239 14.8554 334.375 11.8265 336.822 9.40427C339.564 6.36604 342.612 3.93918 346.28 2.42707C349.633 0.914953 353.3 0.00488281 356.954 0.00488281C361.833 0.00488281 366.106 1.21364 370.375 3.33247C374.338 5.4513 378.006 8.4802 380.743 12.4285C382.57 14.5473 383.791 17.2775 384.702 20.3111C385.618 23.0367 385.918 26.3689 385.918 30.0139V32.7394H342.002V32.7301H342.011Z"
			fill="#232322"
		/>
		<path
			d="M119.122 0C103.353 0 90.3691 12.9043 90.3691 28.5855V78H102.729V52.2473C107.876 55.6309 113.088 57.4698 118.963 57.4698C135.046 57.4698 147.805 44.5654 147.805 28.5809C147.805 12.5963 135.206 0 119.122 0ZM119.122 44.8735C110.153 44.8735 103.039 37.4949 103.039 28.5809C103.039 19.6668 110.153 12.601 119.122 12.601C128.092 12.601 135.516 19.9749 135.516 28.5809C135.516 37.1869 128.092 44.8735 119.122 44.8735Z"
			fill="#232322"
		/>
		<path
			d="M298.645 0C312.282 0 323.439 9.23604 326.539 21.557H313.212C310.732 16.3206 305.153 12.629 298.645 12.629C289.657 12.629 282.524 19.7135 282.524 28.6462C282.524 37.5789 289.652 44.6681 298.645 44.6681C305.153 44.6681 310.732 40.9672 313.212 35.7354H326.539C323.439 48.0517 312.282 57.2924 298.645 57.2924C282.838 57.2924 269.816 44.3554 269.816 28.6462C269.816 12.937 282.838 0 298.645 0Z"
			fill="#232322"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M267.374 28.6509C267.374 12.895 254.404 0 238.546 0H178.926C163.073 0 150.098 12.895 150.098 28.6509C150.098 44.4114 163.073 57.3017 178.926 57.3017H238.546C244.73 57.3017 250.474 55.3323 255.184 52.0047V57.3857H267.309V30.499C267.346 29.8876 267.374 29.2716 267.374 28.6509ZM249.394 39.4317C246.473 42.3346 242.622 43.9307 238.546 43.9307H178.926C174.85 43.9307 170.999 42.3299 168.078 39.4317C165.158 36.5288 163.552 32.7019 163.552 28.6509C163.552 24.5999 165.162 20.7729 168.078 17.87C170.999 14.9672 174.85 13.371 178.926 13.371H238.546C242.622 13.371 246.473 14.9718 249.394 17.87C252.314 20.7729 253.92 24.5999 253.92 28.6509C253.92 32.7019 252.31 36.5288 249.394 39.4317Z"
			fill="url(#paint0_linear_2368_143203)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_2368_143203"
				x1="149.356"
				y1="59.1079"
				x2="260.249"
				y2="10.8744"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FF9E00" />
				<stop offset="1" stopColor="#FF2200" />
			</linearGradient>
		</defs>
	</svg>
);
export default SpaaceLogo;
