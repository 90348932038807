// Modules
import { useAccount, useDisconnect } from "wagmi";

// Components
import { Wallet } from "src/components/Icon";
import { Button } from "src/components/ui";

// Utils
import { shortenAddress } from "src/utils";

// Styles
import styles from "./styles.module.scss";

export const Header = () => {
	const { disconnect } = useDisconnect();

	const { isConnected, address } = useAccount();

	return (
		<>
			<header>
				<div className={styles.header}>
					{isConnected && (
						<>
							<Button
								onClick={disconnect}
								variant="btn-tertiary"
								iconStart={
									<Wallet height="h-[18px]" width="w-[18px]" color={`#000`} />
								}
								label={shortenAddress(address as string)}
							/>
						</>
					)}
				</div>
			</header>
		</>
	);
};
