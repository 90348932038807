import React from "react";
import type { BaseIconProps } from "./types";

const Wallet: React.FC<BaseIconProps> = ({ height, width, color }) => {
	return (
		<svg
			className={`${width ?? "w-[24px]"} ${height ?? "h-[24px]"}`}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.2266 3.00015C17.1123 3.00308 16.9922 3.01773 16.875 3.04703L4.6875 6.25796C3.70312 6.51578 3 7.41812 3 8.43765V18.7502C3 19.9836 4.0166 21.0002 5.25 21.0002H18.75C19.9834 21.0002 21 19.9836 21 18.7502V9.00015C21 7.76675 19.9834 6.75015 18.75 6.75015H8.71875L17.25 4.50015V6.00015H18.75V4.50015C18.75 3.6564 18.0322 2.98843 17.2266 3.00015ZM5.25 8.25015H18.75C19.1748 8.25015 19.5 8.57535 19.5 9.00015V18.7502C19.5 19.175 19.1748 19.5002 18.75 19.5002H5.25C4.8252 19.5002 4.5 19.175 4.5 18.7502V9.00015C4.5 8.57535 4.8252 8.25015 5.25 8.25015ZM16.875 12.7502C16.2539 12.7502 15.75 13.2541 15.75 13.8752C15.75 14.4962 16.2539 15.0002 16.875 15.0002C17.4961 15.0002 18 14.4962 18 13.8752C18 13.2541 17.4961 12.7502 16.875 12.7502Z"
				fill={color ?? "#FFFFFF"}
			/>
		</svg>
	);
};

export default Wallet;
