import React from "react";
import { SpaaceTokenIcon } from "src/components/Icon";

type Props = {
	tokenAmount: string;
};

const BuyRecap: React.FC<Props> = ({ tokenAmount }) => {
	return (
		<div
			className={`mt-6 text-center border-dashed border border-[#d3d3d3] rounded-[8px] p-4`}
		>
			<p className={`text-[#646464] leading-[28px] font-[500] text-[18px]`}>
				You will receive
			</p>
			<div className={`flex items-center gap-[10px] justify-center`}>
				<SpaaceTokenIcon height="60" width="60" />
				<div className={`flex items-baseline justify-center gap-[10px]`}>
					<p className={`leading-[34px] font-[600] text-[#222221] text-[28px]`}>
						{tokenAmount}
					</p>
					<span className={`spaace-txt-color`}>$SPAACE</span>
				</div>
			</div>
		</div>
	);
};

export default BuyRecap;
