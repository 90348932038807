import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Cross } from "src/components/Icon";
import styles from "./_Modal.module.scss";
import { Button } from "../Button";

interface ModalProps {
	isOpen: boolean;
	close?: () => void;
	title?: string;
	children?: React.ReactNode;
}

const Modal = ({ isOpen, close, children, title }: ModalProps) => {
	const [_document, setDocument] = useState<Document | null>(null);

	useEffect(() => {
		setDocument(document);
	}, []);

	const modalContent = isOpen ? (
		<div className={styles.modal}>
			<div className={`container ${styles["modal-container"]}`}>
				{close && (
					<div className={styles["modal-header"]}>
						<Button
							onClick={close}
							size="_notxt"
							iconStart={<Cross color="black" />}
						/>
					</div>
				)}
				{title && <p className={styles["modal-title"]}>{title}</p>}
				<div className={styles["modal-body"]}>{children}</div>
			</div>
		</div>
	) : null;

	if (_document) {
		return ReactDOM.createPortal(modalContent, _document.body);
	} else {
		return null;
	}
};
export default Modal;
