import { BigNumber, BigNumberish, ethers } from "ethers";

export function toWei(amount: string | number): BigNumber {
	if (!amount) return BigNumber.from(0);
	return ethers.utils.parseUnits(amount.toString().replace(/_/g, ""), "ether");
}

export const fromWei = (wei: BigNumber): string => {
	if (!wei || wei.isZero()) return "0";
	return ethers.utils.formatEther(wei);
};

export const pow10 = (precision: number) => BigNumber.from(10).pow(precision);

export const formatAmount = (
	value: BigNumberish | null | undefined,
	precision = 0,
	decimals = 0,
	stripZeros = false,
) => {
	if (value === null || value === undefined) return "N/A";
	value = BigNumber.from(value);

	if (value.gte(pow10(precision + 12)))
		return formatNumber(value, precision + 12, decimals, stripZeros) + "T";
	if (value.gte(pow10(precision + 9)))
		return formatNumber(value, precision + 9, decimals, stripZeros) + "B";
	if (value.gte(pow10(precision + 6)))
		return formatNumber(value, precision + 6, decimals, stripZeros) + "M";
	if (value.gte(pow10(precision + 3)))
		return formatNumber(value, precision + 3, decimals, stripZeros) + "k";

	return formatNumber(value, precision, decimals, stripZeros);
};

export const formatNumber = (
	value: BigNumberish | null | undefined,
	precision = 0,
	decimals = 0,
	stripZeros = false,
) => {
	if (value === null || value === undefined) return "N/A";
	value = BigNumber.from(value);

	const negative = value.isNegative();
	if (negative) value = value.abs();

	const multiplier = pow10(precision);
	let wholeStr = value.div(multiplier).toString();
	wholeStr = wholeStr.replaceAll(/(\d)(?=(\d{3})+$)/g, "$1,");
	if (negative) wholeStr = "-" + wholeStr;

	const fraction = value.mod(multiplier);
	if (decimals > precision) decimals = precision;
	if ((stripZeros && fraction.isZero()) || decimals <= 0) return wholeStr;

	let fractionStr = fraction.toString();
	while (fractionStr.length < precision) fractionStr = "0" + fractionStr;

	fractionStr = fractionStr.substring(0, decimals);
	if (stripZeros) {
		fractionStr = fractionStr.replace(/0+$/, "");
		if (fractionStr === "") return wholeStr;
	}

	return wholeStr + "." + fractionStr;
};

export const formatWei = (
	value: BigNumberish | null | undefined,
	decimals = 2,
	stripZeros = false,
) => formatNumber(value, 18, decimals, stripZeros);

export const formatDelta = (
	delta: BigNumberish | null | undefined,
	value: BigNumberish | null | undefined,
	decimals = 2,
	stripZeros = false,
) => {
	if (
		delta === null ||
		delta === undefined ||
		value === null ||
		value === undefined
	)
		return "N/A";
	delta = BigNumber.from(delta);
	value = BigNumber.from(value);

	const PRECISION = pow10(decimals + 2);
	const ratio = delta.eq(value)
		? PRECISION
		: BigNumber.from(delta).mul(PRECISION).div(value.sub(delta));

	let ratioStr = formatNumber(ratio, decimals, decimals, stripZeros);
	if (!ratio.isNegative()) ratioStr = "+" + ratioStr;

	return ratioStr + " %";
};

export const formatRelativeDate = (date: Date | null | undefined) => {
	if (date === null || date === undefined) return "N/A";

	const interval = date.getTime() - Date.now();

	let seconds = Math.floor(Math.abs(interval) / 1000);
	let minutes = Math.floor(seconds / 60);
	let hours = Math.floor(minutes / 60);
	let days = Math.floor(hours / 24);

	let abs: string;
	if (days > 0) abs = `${days} day${days > 1 ? "s" : ""}`;
	else if (hours > 0) abs = `${hours} hour${hours > 1 ? "s" : ""}`;
	else if (minutes > 0) abs = `${minutes} min`;
	else if (seconds > 0) abs = `${seconds}s`;
	else return "just now";

	if (interval < 0) return `${abs} ago`;
	else return `in ${abs}`;
};

export const shortenAddress = (hexAddress: `${string}`) => {
	return hexAddress.slice(0, 5) + "..." + hexAddress.slice(-3);
};
