import {useAccount} from "wagmi";
import {useConnectModal} from "@rainbow-me/rainbowkit";


function ConnectWalletButton() {
    const {isConnecting} = useAccount();
    const { openConnectModal } = useConnectModal();


    return (
        <button
            onClick={openConnectModal}
            type="button"
            className={`mt-[18px] w-full rounded-[40px] p-3 text-white flex justify-center ${
                isConnecting ? "disabled bg-[#F5F4F4] " : "bg-[#FF7225] "
            } ${isConnecting ? "pr-3" : ""}`}
            disabled={isConnecting}
        >
            {isConnecting ? (
                <div className="loader"/>
            ) : (
                <p>Connect Wallet</p>
            )}
        </button>
    );
}

export default ConnectWalletButton;
