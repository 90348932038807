// Modules
import BackgroundGradient from "src/assets/images/background-gradient.png";

// Styles
import styles from "./styles.module.scss";

// Components
import { Footer } from "src/components/base";

type Props = {
	children: any;
	gradient?: boolean;
};

export const Layout = ({ children, gradient = false }: Props) => {
	return (
		<div className={styles.container}>
			{gradient && (
				<img
					className={styles.bgGradient}
					src={BackgroundGradient}
					aria-hidden
					alt=""
				/>
			)}
			<main className={styles.main}>{children}</main>
			<Footer />
		</div>
	);
};
