// React
import React, { useState } from "react";

// Styles
import styles from "./styles.module.scss";

// Assets
import badge0 from "src/assets/icons/badges/badge-0.png";
import badge1 from "src/assets/icons/badges/badge-1.png";
import badge2 from "src/assets/icons/badges/badge-2.png";
import usdtLogo from "src/assets/images/usdt-logo.png";

import CheckMarkBlue from "src/assets/icons/check-mark-blue.svg";

type Props = {
	level: number;
	price: number;
	isActive?: boolean;
	disabled?: boolean;
	toggleOptionCardActive: () => void;
};

export const OptionCard: React.FC<Props> = ({
	level,
	price,
	isActive,
	toggleOptionCardActive,
}) => {
	const badges = [badge0, badge1, badge2];

	const [hover, setHover] = useState<boolean>(false);

	const onClickOnCard = () => {
		toggleOptionCardActive();
	};

	return (
		<div
			className={`${styles.container} ${isActive ? styles.active : ""} ${
				level === 2 ? styles.maxLevel : ""
			}`}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={onClickOnCard}
		>
			<img
				width={"124"}
				height={"124"}
				src={badges[level]}
				className={`${styles.badge} ${hover ? styles.badgeHover : ""}`}
				alt="badge"
			/>
			{isActive && (
				<img
					src={CheckMarkBlue}
					className={styles.checkMark}
					alt="check mark"
				/>
			)}

			<div className={styles.prices}>
				<p className={styles.price}>{
					new Intl.NumberFormat("en-US", {
						minimumFractionDigits: 0,
					}).format(price)
				}</p>
				<div className={styles.usdtContainer}>USDT<img className={styles.usdPrice} src={usdtLogo} alt="Usdt logo"/></div>
			</div>
		</div>
	);
};

export default OptionCard;
