import React from "react";
import type { BaseIconProps } from "./types";

export const Cross: React.FC<BaseIconProps> = ({ height, width, color }) => {
	return (
		<svg
			width={width ?? "12"}
			height={height ?? "12"}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 1L10.9375 10.6712M10.9375 1L1 10.6712"
				stroke={color ?? "white"}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
