import React from "react";
import type { BaseIconProps } from "./types";
/**
 * Prefere using hex colors to avoid any naming issues
 */
const EthereumSvg: React.FC<BaseIconProps> = ({ height, width, color }) => {
	return (
		<svg
			width={width ?? "17"}
			height={height ?? "17"}
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_9_168)">
				<path
					d="M12.5506 8.15781L7.80371 11.0578L3.05371 8.15781L7.80371 0.0078125L12.5506 8.15781ZM7.80371 11.9891L3.05371 9.08906L7.80371 16.0078L12.5537 9.08906L7.80371 11.9891Z"
					fill={color ?? "white"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_9_168">
					<rect
						width="16"
						height="16"
						fill={color ?? "white"}
						transform="translate(0.0537109 0.0078125)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default EthereumSvg;
