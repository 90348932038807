export const BP_MOBILE = 767;
export const BP_TABLET_NAVBAR = 1100;
export const BP_TABLET = 1280;
export const BP_DESKTOP = 1920;

export const enum BP {
	DESKTOP,
	TABLET,
	MOBILE,
}

export const getWindowSize = () => {
	let currentBp: BP;

	if (window.innerWidth > BP_TABLET_NAVBAR) {
		currentBp = BP.DESKTOP;
	} else if (window.innerWidth > BP_MOBILE) {
		currentBp = BP.TABLET;
	} else {
		currentBp = BP.MOBILE;
	}
	return currentBp;
};
