// Styles
import styles from "./styles.module.scss";

// Components
import { EthereumIcon, SpaaceTokenIcon, WeirdIcon } from "src/components/Icon";

export const OdysseySaleBenefits = () => {
	return (
		<div className={styles.container}>
			<p className={styles.title}>Benefits:</p>

			<div className={styles.content}>
				<div className={styles.summary}>
					<SpaaceTokenIcon color="#000" height="30" width="30"/>
					<p>Best $SPAACE Price</p>
				</div>

				<div className={styles.summary}>
					<EthereumIcon color="#646463" height="30" width="30"/>
					<p>Daily ETH rewards, starting at day 1</p>
				</div>

				<div className={styles.summary}>
					<WeirdIcon color="#000" height="30" width="30"/>
					<p>No cliff, no vesting on ETH rewards</p>
				</div>

				<div className={styles.countdown}>
					<p>Private Sale ends soon</p>
				</div>
			</div>
		</div>
	);
};
