import React from "react";
import styles from "./_Button.module.scss";

export interface ButtonProps {
	onClick?: (e: any) => void;
	label?: string;
	variant?: "btn-primary" | "btn-secondary" | "btn-tertiary" | "base";
	size?: "_xs" | "_sm" | "_md" | "_lg" | "_notxt";
	disabled?: boolean;
	className?: string;
	iconStart?: React.ReactNode;
	iconEnd?: React.ReactNode;
	separator?: boolean;
	separatorColor?: string;
	style?: React.CSSProperties;
	href?: string;
	target?: string;
}

export const Button = ({
	onClick,
	label,
	variant = "base",
	size = "_md",
	iconStart,
	iconEnd,
	separator,
	separatorColor,
	disabled,
	className,
	style,
	href,
	target,
}: ButtonProps) => {
	const Tag = href ? "a" : "button";

	return (
		<Tag
			href={href}
			target={target}
			onClick={onClick}
			className={
				`${styles.btn} ${styles[variant]} ${styles[size]}` +
				(className ? ` ${className}` : ``)
			}
			style={style}
			disabled={disabled}
		>
			<div className={`${iconStart && styles.icon}`}>
				{iconStart && (
					<>
						{separator && <div className={`${styles.separator}`}></div>}
						<div>{iconStart}</div>
					</>
				)}
			</div>
			<div className={`${label && styles.label}`}>{label && label}</div>
			<div className={`${iconEnd && styles.icon}`}>
				{iconEnd && (
					<>
						{separator && (
							<div
								className={`${styles.separator} ${
									separatorColor ? `!border-${separatorColor}` : ""
								}`}
							></div>
						)}
						<div>{iconEnd}</div>
					</>
				)}
			</div>
		</Tag>
	);
};
