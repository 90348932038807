// Assets
import CheckmarkIcon from "src/assets/icons/checkmark-orange.svg";
import GreenCircleIcon from "src/assets/icons/green-circle.svg";

// Styles
import styles from "./styles.module.scss";
import {PRIVATE_SALE_PRICE} from "../../../../constants";
import {formatNumber} from "../../../../utils";

interface SaleStagesProps {
	stageStatus: number;
}

export const SaleStages = ({stageStatus}: SaleStagesProps) => {
	const STAGES = [
		{
			title: "PRE-SEED",
			subtitle: "$0.05",
		},
		{
			title: "SEED",
			subtitle: "$0.10",
		},
		{
			title: "PRIVATE SALE",
			subtitle: `$${formatNumber(PRIVATE_SALE_PRICE, 18, 2)}`,
		},
		{
			title: "PUBLIC SALE",
			subtitle: "$0.25",
		},
		{
			title: "LISTING TBD",
			subtitle: "TBD",
		},
	];

	const getStatus = (index: number) => {
		if (index === stageStatus) return "currently"
		if (index < stageStatus) return "finished"
		return null
	}

	return (
		<div className={styles.saleStages}>
			{STAGES.map((stage, index) => (
				<div
					className={`${styles.stage} ${
						getStatus(index) === "finished" && styles.stageFinished
					} ${getStatus(index) === "currently" && styles.stageCurrently}`}
					key={index}
				>
					<div className={styles.leftPart}>
						{getStatus(index) !== null ? (
							<img
								className={styles.circle}
								src={
									getStatus(index) === "finished" ? CheckmarkIcon : GreenCircleIcon
								}
								alt="Verified icon"
							/>
						) : (
							<span className={styles.circle} />
						)}
						<span className={styles.line} />
					</div>
					<div className={styles.rightPart}>
						<div className={styles.title}>
							{stage.title}
							{getStatus(index) === "finished" && <span className={styles.line} />}
						</div>
						<div className={styles.subtitle}>
							{stage.subtitle}
							{getStatus(index) === "finished" && <span className={styles.line} />}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
