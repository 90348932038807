import ethTokenGif from "src/assets/loaders/eth-token/eth-token.gif";
import spaaceTokenGif from "src/assets/loaders/spaace-token/spaace-token.gif";
import infiniteLoader from "src/assets/loaders/infinite-loader/loader-infinite_transparent.gif";
import { useEffect, useState } from "react";
import {formatNumber, toWei} from "../../../../utils";
import { Button } from "../../../ui";

import styles from "./styles.module.scss";
import {PRIVATE_SALE_PRICE} from "../../../../constants";

interface LoadingPanelProps {
	isFinished?: boolean;
	usdAmount?: number;
	reset: () => void;
}

export const PostTransactionPanel = ({
	isFinished,
	usdAmount,
	reset,
}: LoadingPanelProps) => {
	const [showThanks, setShowThanks] = useState(false);

	useEffect(() => {
		if (isFinished) {
			const timeout = setTimeout(() => setShowThanks(true), 3000);

			return () => clearTimeout(timeout);
		}
	}, [isFinished]);

	const centerClass =
		"absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ";
	const fadeIn = (trigger: boolean | undefined) =>
		centerClass +
		`transition-all duration-500 opacity-1 ${
			trigger ? "opacity-1" : "opacity-0"
		}`;

	return (
		<div className={styles.postTransactionPanel}>
			{showThanks && (
				<div className={styles.titles}>
					<p
						className={`${styles.title} text-[28px] color-[#646463] text-center font-[600]`}
					>
						Transaction Confirmed
					</p>
					<p
						className={`${styles.subtitle} text-[20px] color-[#646463] text-center font-[600]`}
					>
						Congratulations! You have successfully purchased
					</p>
				</div>
			)}

			<div className={styles.loader}>
				{!showThanks && <img aria-hidden alt="" src={infiniteLoader} />}
				<img
					aria-hidden
					alt=""
					src={spaaceTokenGif}
					className={fadeIn(isFinished)}
				/>
				<img
					aria-hidden
					alt=""
					src={ethTokenGif}
					className={fadeIn(!isFinished)}
				/>
			</div>

			{showThanks && usdAmount && (
				<>
					<div className={styles.amount}>
						<div
							className={`flex justify-center gap-[10px] items-center mt-[20px]`}
						>
							<p
								className={`text-[#222221] golden-gradient-animated-txt text-[40px] font-[700]`}
							>
								{
									formatNumber(toWei(usdAmount).div(PRIVATE_SALE_PRICE),
									0,
									2,
									true)
								}
							</p>
						</div>
						<p className="spaace-txt-color mb-[32px] text-[40px] font-[700]">
							$SPAACE
						</p>
						<Button
							variant="btn-secondary"
							label="Deposit more"
							size="_md"
							onClick={reset}
						/>
					</div>
				</>
			)}
		</div>
	);
};
