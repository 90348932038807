import { toWei } from "../utils";

export const TESTNET = false;

export const EXPLORER_URL = TESTNET
	? "https://testnet.bscscan.com"
	: "https://etherscan.io";

export const USDT_CONTRACT = process.env
	.REACT_APP_USDT_CONTRACT as `0x${string}`;

export const MULTISIG_ADDRESS = process.env
	.REACT_APP_MULTISIG_ADDRESS as `0x${string}`;

export const DEPOSIT_OPTIONS = process.env
	.REACT_APP_DEPOSIT_OPTIONS!.split(",")
	.map((option) => +option);

export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

export const PRIVATE_SALE_PRICE = toWei(0.15);
