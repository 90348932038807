import React from "react";

import Modal from "./Modal";
import Wallet from "src/components/Icon/Wallet";

type Props = {
	isOpen: boolean;
};

export const TransactionModal: React.FC<Props> = ({ isOpen }) => {
	return (
		<Modal title="Confirming transaction" isOpen={isOpen}>
			<span
				className={`text-[14px] font-[500] !mt-[30px] leading-[18px] text-[#949494]`}
			>
				You're almost there!
			</span>
			<div
				className={`border border-dashed mt-[10px] flex justify-between gap-[10px] items-center border-[#18A60C] bg-[#4FB54626] px-[15px] py-[10px] rounded-[10px]`}
			>
				<div className={`flex items-center`}>
					<Wallet color="#18A60C" />
				</div>
				<p className={`text-[12px] leading-[20px] font-[400] text-[#18A60C]`}>
					Please go to your wallet and confirm your transaction.
				</p>
			</div>

			<div className={`mt-[35px] border-t border-[#ACACAB]`}>
				<div
					className={`flex text-[14px] font-[400] justify-between items-center mt-[20px]`}
				>
					<p className={`text-[#949494]`}> New to Ethereum Wallets? </p>
					<a
						className={`text-[#0052FE] font-[700]`}
						href="https://ethereum.org/en/wallets/"
						target="_blank"
						rel="noreferrer noopener"
					>
						{" "}
						Learn more{" "}
					</a>
				</div>
			</div>
		</Modal>
	);
};
